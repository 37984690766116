import React from "react"
import { useRouter } from "next/router"
import Link from "next/link"
import styled from "styled-components"
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined"
import DropDownButton from "@/common/components/_UI/DropDownButton"
import { theme } from "@/common/Mixin"
import featureSwitch from "@/features.config"

export default function DeskTopLanguageBtn({ t }: { t: any }) {
  const router = useRouter()
  return (
    <Wrapper className="desktop">
      <DropDownButton>
        <LanguageOutlinedIcon width="24px" />
      </DropDownButton>
      <DropDownContent className="dropdown-content">
        <DropDownContentList className={"DropDownContentList"}>
          <MenuListCardsWrapper>
            <Link
              href={{ pathname: router.pathname, query: router.query }}
              locale={"en"}
              passHref
            >
              {t("locale.en")}
            </Link>
            <Link
              href={{ pathname: router.pathname, query: router.query }}
              locale={"tw"}
              passHref
            >
              {t("locale.tw")}
            </Link>
            <Link
              href={{ pathname: router.pathname, query: router.query }}
              locale={"cn"}
              passHref
            >
              {t("locale.cn")}
            </Link>
            {featureSwitch.jpLang && (
              <Link
                href={{ pathname: router.pathname, query: router.query }}
                locale={"ja"}
                passHref
              >
                {t("locale.ja")}
              </Link>
            )}
          </MenuListCardsWrapper>
        </DropDownContentList>
      </DropDownContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80px;

  &:hover {
    .dropdown-content {
      z-index: 3;
      grid-template-rows: 1fr;
      opacity: 1;
      padding-top: 20px;
    }
    .DropDownContentList {
      padding: 24px 2rem 40px;
    }
  }
`
const DropDownContent = styled.div`
  z-index: -1;
  margin: 0 auto;
  display: grid;
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: 0;
  transform: translate(0, 100%);
  transition:
    opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  grid-template-rows: 0fr;
  opacity: 0;

  /* text-transform: uppercase; */
`

const DropDownContentList = styled.div`
  border-radius: 12px;
  transition:
    opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0rem 2rem;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  ${theme.menuBG}
  display: grid;
  grid-template-columns: 1fr;
  /* width: 100%; */
  margin: 0 auto;
  overflow: hidden;
  &.twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
const MenuListCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  min-width: 100px;
  gap: 16px;
  a {
    color: #fff;
    font-size: 18px;
    transition: all 0.3s ease-in;
    &:hover {
      color: ${theme.color.primary};
    }
  }
`
