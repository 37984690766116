import React, { MouseEventHandler, useState } from "react"
import { useRouter } from "next/router"
import styled from "styled-components"
import MenuIcon from "@mui/icons-material/Menu"
import CloseIcon from "@mui/icons-material/Close"
import { themeGet } from "@styled-system/theme-get"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import { theme } from "@/common/Mixin"

import logo from "@/common/assets/image/new/logo.svg"
import Button from "@/common/components/_UI/ButtonNew"
import NextImg from "@/common/components/NextImg/index"

import { navMenu } from "@/containers/common/menu.data.new"
import DesktopMenuList from "@/containers/common/NavbarNew/views/DesktopMenuList"

import MobileMenu from "./views/MobileMenu"
import DeskTopLanguageBtn from "./views/DeskTopLanguageBtn"
import { Drawer } from "@mui/material"
import LazyLoadComponent from "@/common/components/LazyLoadComponent"

interface NavbarProps {
  contactOnClick?: MouseEventHandler<HTMLButtonElement>
}

const Navbar = ({ contactOnClick }: NavbarProps) => {
  const router = useRouter()
  const { t } = useTranslation("common")
  const [state, setState] = useState({
    search: "",
    searchToggle: false,
    drawer: false,
    mobileMenu: false
  })
  const [showDropdown, setShowDropdown] = useState("close")
  const [MobileMenuTab, setMobileMenuTab] = useState(null)

  const toggleHandler = (type: "search" | "menu") => {
    if (type === "search") {
      setState({
        ...state,
        search: "",
        searchToggle: !state.searchToggle,
        mobileMenu: false
      })
    }

    if (type === "menu") {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu
      })
    }
  }
  return (
    <>
      <NavbarWrapper className={showDropdown}>
        <Wrapper>
          <NavbarLeft>
            <Link href="/">
              <NextImg src={logo} alt="logo" />
            </Link>
          </NavbarLeft>
          <NavbarCenter>
            <DesktopMenuListsWrapper>
              {navMenu?.map(d => (
                <DesktopMenuList
                  key={d.id}
                  id={d.id}
                  data={d.itemColumn}
                  category={d.category}
                  setShowDropdown={setShowDropdown}
                />
              ))}
            </DesktopMenuListsWrapper>
          </NavbarCenter>
          <NavbarRight>
            <DeskTopWrapper>
              <DeskTopLanguageBtn t={t} />
              <Button
                id="navbar-contact"
                content={t("navbar.cta")}
                arrowType="none"
                href={`/contact-us${
                  router.asPath.includes("/contact-us") === true
                    ? ""
                    : `?referrer=header-${
                        router.asPath === "/" ? "home" : router.asPath
                      }`
                }`}
                styleType="subtle"
                onClick={
                  contactOnClick ??
                  (e => {
                    e.preventDefault()
                    if (!router.asPath.includes("/contact-us"))
                      setState({
                        ...state,
                        drawer: !state.drawer
                      })
                  })
                }
              />
            </DeskTopWrapper>

            <Drawer
              keepMounted
              anchor="right"
              open={state.drawer}
              onClose={() => {
                setState({
                  ...state,
                  drawer: !state.drawer
                })
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px"
                }}
              >
                <LazyLoadComponent
                  skeletonProps={{
                    width: "75%"
                  }}
                  props={{ page: "contact-us" }}
                  component={() =>
                    import("@/common/components/ContactUs/ContactUsForm")
                  }
                />
              </div>
            </Drawer>

            <MobileMenuBurger onClick={() => toggleHandler("menu")}>
              <div className="mobile-menu-btn">
                {state.mobileMenu ? (
                  <CloseIcon style={{ width: "40px" }} />
                ) : (
                  <MenuIcon style={{ width: "40px" }} />
                )}
              </div>
            </MobileMenuBurger>
          </NavbarRight>
        </Wrapper>

        <MobileMenu
          className={`mobile-menu ${state.mobileMenu ? "active" : ""}`}
          MobileMenuTab={MobileMenuTab}
          setMobileMenuTab={setMobileMenuTab}
          data={navMenu}
        />
      </NavbarWrapper>
    </>
  )
}

export default Navbar

const NavbarWrapper = styled.nav`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    ${themeGet("colors.background")},
    rgba(0, 0, 0, 0)
  );
  position: fixed;
  z-index: 1;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &.active,
  &:hover {
    background-color: ${themeGet("colors.background")};
  }
  @media (${theme.xl}) {
    height: 64px;
  }

  > div.container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    @media only screen and (max-width: 1200px) {
      padding: 10px 20px;
    }
    @media only screen and (max-width: 1200px) {
      padding: 8px 16px;
    }
  }
`

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0.5em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`
export const NavbarLeft = styled.div`
  display: flex;
  width: clamp(100px, 100%, 178px);
  cursor: pointer;
  a {
    width: 100%;
  }
  @media (${theme.lg}) {
    width: clamp(100px, 100%, 120px);
  }
`
export const NavbarCenter = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  // margin-left: 36px;
  justify-content: start;
  @media (${theme.lg}) {
    display: none;
  }
`
const DesktopMenuListsWrapper = styled.div`
  display: flex;
  margin-left: 1em;
  justify-content: start;
  gap: 0.5em;
`

export const NavbarRight = styled.div`
  display: flex;
`

const DeskTopWrapper = styled.div`
  gap: 16px;
  align-items: center;
  display: flex;
  @media (${theme.lg}) {
    display: none;
  }
`

export const MobileMenuBurger = styled.span`
  display: none;
  margin: 0;
  color: ${themeGet("colors.primary")};
  .mobile-menu-btn {
    height: 48px;
    width: 48px;
    margin-right: -8px;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 32px;
      height: 32px;
    }
    @media (${theme.sm}) {
      width: 40px;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (${theme.lg}) {
    display: inline-flex;
    padding: 0;
    justify-content: flex-end;
    min-width: 35px;
    color: #fff;
    svg {
      width: 27px;
      height: auto;
    }
  }
`
