import { theme } from "@/common/Mixin"
import { NavMenuItem } from "@/containers/common/menu.data.new"
import Link from "next/link"
import { useRouter } from "next/router"
import styled from "styled-components"
import NextImg from "../NextImg/index"

type MenuButtonProps = NavMenuItem

export default function MenuButton({
  id,
  icon,
  label,
  route,
  localizedRoute,
  target = "_self",
  disabled
}: MenuButtonProps) {
  const { locale } = useRouter()

  const href = localizedRoute?.find(e => e.locale === locale)?.href || route

  return (
    <Link
      id={id}
      target={target}
      href={href}
      style={{ pointerEvents: disabled ? "none" : "auto" }}
    >
      <Wrapper className={icon && "icon"}>
        {icon && (
          <IconWrapper>
            <NextImg src={icon} />
          </IconWrapper>
        )}
        <LabelWrapper disabled={disabled} className="body">
          {label}
        </LabelWrapper>
      </Wrapper>
    </Link>
  )
}
const Wrapper = styled.div`
  margin: 4px 0;
  display: grid;
  color: white;
  min-height: 35px;
  gap: 8px;
  justify-items: start;
  align-items: center;
  padding: 0px 8px;
  transition: 0.2s ease;
  border-radius: 4px;
  &:hover {
    background: ${theme.color.buttonHover};
  }
  &.icon {
    grid-template-columns: 24px 1fr;
  }
  p {
    color: white;
    /* font-weight: 400; */
  }
`

const IconWrapper = styled.div`
  width: 100%;
`

const LabelWrapper = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? theme.color.textSecondary : theme.color.textContrast};
`
