import { theme } from "@/common/Mixin"
import { NavMenuItemGroup } from "@/containers/common/menu.data.new"
import { useTranslation } from "next-i18next"
import styled from "styled-components"
import MenuButton from "./MenuButton"

interface MenuListCardProps {
  groups: NavMenuItemGroup[]
  id?: string
}

export default function MenuListCard({ groups, id }: MenuListCardProps) {
  const { t } = useTranslation(["common"])
  return (
    <ListsCard>
      {groups.map(({ label, items }, index) => (
        <GroupWrapper key={index}>
          {label && <Label>{t(label)}</Label>}
          <ListsWrapper>
            {items?.map((props, index) => (
              <MenuButton
                {...props}
                key={`${props.label}_${index}`}
                label={t(props.label)}
              />
            ))}
          </ListsWrapper>
        </GroupWrapper>
      ))}
    </ListsCard>
  )
}

const ListsCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  min-width: 210px;
  align-content: start;
  @media (${theme.lg}) {
    min-width: auto;
  }
`

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const ListsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  justify-items: start;
  &.twoCol {
    grid-template-columns: 1fr 1fr;
  }
`

const Label = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${theme.color.primary};
  padding: 4px 8px;
  @media (${theme.xl}) {
    font-size: 12px;
  }
`
