import React from "react"
import styled from "styled-components"
import { theme } from "@/common/Mixin"

export default function DropDownButton({ children }) {
  return <Wrapper>{children}</Wrapper>
}

const Wrapper = styled.p`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 16px;
  height: 52px;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  transition: all 0.3s ease;
  color: ${theme.color.title};
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  border-bottom: 2px solid ${theme.color.primary}00;
  text-shadow: 0 0 4px ${theme.color.background};
  box-shadow: 0 0 0 0 ${theme.color.primary};
  border-radius: 4px;
  @media (max-width: 1024px) {
    padding: 1rem 1rem;
    padding-top: 1rem;
    > svg {
      position: relative;
      z-index: 10;
    }
  }
  &:hover {
    background: ${theme.color.buttonHover};
    z-index: 4;
    color: ${theme.color.white};
  }
  &.active {
    color: ${theme.color.white};
  }
`
