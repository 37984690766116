import React from "react"
import { useRouter } from "next/router"
import { useState } from "react"
import styled from "styled-components"
import Link from "next/link"
import LanguageIcon from "@mui/icons-material/Language"
import { useTranslation } from "next-i18next"
import { Fade } from "react-reveal"
import featureSwitch from "@/features.config"

export default function MobileLanguageBtn() {
  const router = useRouter()
  const { t } = useTranslation("common")
  const [toggle, setToggle] = useState(false)

  const dropdownToggle = () => {
    setToggle(!toggle)
  }

  return (
    <Wrapper className="dropdown-wrapper mobile-language">
      <div className="dropdown-btn-wrapper" onClick={dropdownToggle}>
        <LanguageIcon width="24px" />
      </div>
      {toggle === true && (
        <Fade>
          <div className="languagesWrapper">
            <Link
              href={{ pathname: router.pathname, query: router.query }}
              locale={"en"}
              passHref
            >
              {t("locale.en")}
            </Link>
            <Link
              href={{ pathname: router.pathname, query: router.query }}
              locale={"tw"}
              passHref
            >
              {t("locale.tw")}
            </Link>
            <Link
              href={{ pathname: router.pathname, query: router.query }}
              locale={"cn"}
              passHref
            >
              {t("locale.cn")}
            </Link>
            {featureSwitch.jpLang && (
              <Link
                href={{ pathname: router.pathname, query: router.query }}
                locale={"ja"}
                passHref
              >
                {t("locale.ja")}
              </Link>
            )}
          </div>
        </Fade>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 1em;
  min-height: 72px;
  gap: 1em;
  .languagesWrapper {
    // width: 100%;

    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 8px;
    a {
      color: #fff;
      display: flex;
      padding: 0.25em;
      border-radius: 0.5em;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
  .dropdown-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
