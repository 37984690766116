import React from "react"
import styled from "styled-components"
import MobileMenuList from "@/containers/common/Navbar/views/MobileMenuList"
import MobileLanguageBtn from "@/containers/common/Navbar/views/MobileLanguageBtn"
import { themeGet } from "@styled-system/theme-get"
import { theme } from "@/common/Mixin"
import NewsLetter from "@/containers/common/NewsLetter"
import Button from "@/common/components/_UI/ButtonNew"
import { useTranslation } from "next-i18next"
import { NavMenu } from "@/containers/common/menu.data.new"

interface MobileMenuProps {
  className?: string
  data: NavMenu[]
  MobileMenuTab: number | null
  setMobileMenuTab: any
}

export default function MobileMenu({
  className,
  data,
  MobileMenuTab,
  setMobileMenuTab
}: MobileMenuProps) {
  const { t } = useTranslation("common")
  return (
    <Wrapper className={className}>
      {data.map((menu, index) => {
        return (
          <MobileMenuList
            key={`menu_key_${menu?.id}`}
            active={MobileMenuTab}
            setActive={setMobileMenuTab}
            menu={menu}
            index={index}
          />
        )
      })}
      <GetStartedWrapper>
        {/* <div className="button-l" style={{ marginBottom: "12px" }}>
          {t("GetStarted.title")}
        </div> */}
        <Button wide content={t("GetStarted.button")} href="/contact-us" />
      </GetStartedWrapper>
      <NewsLetterWrapper>
        <NewsLetter titleType="buttonL" />
      </NewsLetterWrapper>
      <MobileLanguageBtn />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  justify-content: flex-start;
  overflow-y: hidden;
  display: none;
  a:hover {
    color: ${themeGet("colors.primary")};
  }

  @media only screen and (${theme.lg}) {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 64px 0 0;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    flex-direction: column;
    ${theme.menuBG}

    transition: all 0.3s ease;
    color: ${themeGet("colors.title")};
    transform: translateY(-50%);
    z-index: -1;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ul {
      padding-bottom: 20px;
      li {
        a {
          display: block;
          padding: 16px 0;
          border-radius: 5px;
          transition: all 0.3s ease;
          color: ${themeGet("colors.primary")};
        }
      }
    }
    .reusecore__button {
      width: 100%;
      border-radius: 5px;
      @media only screen and (max-width: 480px) {
        margin-top: 20px;
      }
    }
  }
  .dropdown-wrapper.mobile-language {
    // display: flex;
    // justify-content: flex-end;
    // transition: 2s;
    // position: relative;

    &:hover .dropdown-content-language {
      transform: translateX(0px);
    }
  }
`
const NewsLetterWrapper = styled.div`
  padding: 16px 24px;
`

const GetStartedWrapper = styled.div`
  padding: 32px 24px 16px 24px;
`
