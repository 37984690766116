import React from "react"
import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { useTranslation } from "next-i18next"
import MenuListCard from "@/common/components/_UI/MenuListCard"
import { theme } from "@/common/Mixin"
import { NavMenu, productCustomerNav } from "@/containers/common/menu.data.new"

interface MobileMenuListProps {
  active: number | null
  setActive: any
  menu: NavMenu
  index: number
}

export default function MobileMenuList({
  active,
  setActive,
  menu,
  index
}: MobileMenuListProps) {
  const { t } = useTranslation("common")
  return (
    <Wrapper className="mobile-item">
      <li
        onClick={() => {
          active === index ? setActive(null) : setActive(index)
        }}
        className={`${active === index ? "selected" : ""}`}
      >
        <h5 className="label">{t(menu.category)}</h5>
        <KeyboardArrowDownIcon
          width="24px"
          className={`${
            active === index ? "arrow-down-selected arrow" : "arrow"
          }`}
        />
      </li>
      <div
        className={`${
          active === index
            ? "mobile-subitem-list active"
            : "mobile-subitem-list"
        }`}
      >
        {menu?.itemColumn?.map((d, index) => (
          <MenuListCard
            key={`${d.groups[0].label}_${index}`}
            groups={d.groups}
          />
        ))}
        {active === 0 &&
          productCustomerNav?.map(({ groups }, index) => (
            <MenuListCard key={`${groups[0].label}_${index}`} groups={groups} />
          ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 0 24px;
  li {
    display: flex;
    justify-content: space-between;
    padding: 24px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    .arrow {
      transition: all 0.3s ease;
    }
    .label {
      transition: all 0.3s ease;
    }
    &.selected {
      .label {
        color: ${themeGet("colors.primary")};
      }
    }
    h5 {
      margin-block-start: 0 !important;
      color: ${theme.color.primary};
    }
  }
  .mobile-subitem-list {
    transition: all 0.3s ease;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    max-height: 0px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    padding: 0 4px;
    &.active {
      padding: 32px 4px;
      opacity: 100%;
      max-height: 200vh;
      padding-bottom: 1.5rem;
      pointer-events: auto;
    }
  }
  .arrow-down-selected {
    transform: rotate(180deg);
    color: ${themeGet("colors.primary")};
  }
`
