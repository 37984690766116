/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import styled from "styled-components"
import { useTranslation } from "next-i18next"
import DropDownButton from "@/common/components/_UI/DropDownButton"
import { theme } from "@/common/Mixin"
import MenuListCard from "@/common/components/_UI/MenuListCard"
import {
  NavMenuItemColumn,
  productCustomerNav
} from "@/containers/common/menu.data.new"
import Button from "@/common/components/_UI/ButtonNew"

interface DesktopMenuListProps {
  data: NavMenuItemColumn[]
  category: string
  setShowDropdown: any
  id: string
}

const Badge = styled.span`
  margin-left: 0.8em;
  color: black;
  padding: 4px 8px;
  padding-bottom: 2px;
  font-size: 12px;
  border-radius: 8px;
`

const NavBlock = styled.div`
  padding-bottom: 1em;
`

const SectionWrapper = styled.div`
  display: flex;
`

const BusinessSection = styled.div`
  padding: 40px;
  padding-right: 0;
`
const CustomerSection = styled.div`
  padding: 40px;
  background: #03122e;
`

export default function DesktopMenuList({
  data,
  category,
  id
}: DesktopMenuListProps) {
  const { t } = useTranslation("common")
  return (
    <Wrapper className="dropdown-wrapper">
      <DropDownButton>{t(category)}</DropDownButton>
      <DropDownContent className="dropdown-content">
        <DropDownContentList className={`DropDownContentList`}>
          <SectionWrapper>
            <BusinessSection>
              {id === "Products" && (
                <NavBlock>
                  {t("navbar.users.studio.title")}
                  <Badge style={{ background: "#74caff" }}>Business</Badge>
                </NavBlock>
              )}
              <MenuListCardsWrapper>
                {data?.map(({ groups }, index) => (
                  <MenuListCard
                    key={`${groups[0].label}_${index}`}
                    groups={groups}
                    id={id}
                  />
                ))}
              </MenuListCardsWrapper>
            </BusinessSection>
            {id === "Products" && (
              <CustomerSection>
                <NavBlock>
                  {t("navbar.users.app.title")}
                  <Badge style={{ background: "#FFC211" }}>Customer</Badge>
                </NavBlock>
                <MenuListCardsWrapper>
                  {productCustomerNav?.map(({ groups }, index) => (
                    <MenuListCard
                      key={`${groups[0].label}_${index}`}
                      groups={groups}
                      id={id}
                    />
                  ))}
                </MenuListCardsWrapper>
                <ButtonWrapper>
                  <Button
                    styleType="text"
                    className="button-header"
                    content={t("navbar.product.showAll")}
                    href={"/products"}
                  />
                </ButtonWrapper>
              </CustomerSection>
            )}
          </SectionWrapper>
          {id == "Solutions" && (
            <SolutionButtonWrapper>
              <Button
                styleType="text"
                className="button-header"
                content={t("navbar.solutions.showAll")}
                href={"/solutions"}
              />
            </SolutionButtonWrapper>
          )}
        </DropDownContentList>
      </DropDownContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  &:hover {
    .dropdown-content {
      z-index: 3;
      grid-template-rows: 1fr;
      opacity: 1;
    }
  }
`

const DropDownContent = styled.div`
  padding-top: 20px;
  z-index: -1;
  margin: 0 auto;
  display: grid;
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: 0;
  transform: translate(0, 100%);
  transition:
    opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  grid-template-rows: 0fr;
`

const DropDownContentList = styled.div`
  border-radius: 12px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  ${theme.menuBG}

  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  overflow: hidden;

  &.twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const MenuListCardsWrapper = styled.div`
  padding: 4px 8px;
  display: flex;
  width: 100%;
`

const ButtonWrapper = styled.div`
  margin-top: 7em;
  width: 100%;
  display: flex;
`

const SolutionButtonWrapper = styled.div`
  padding: 2em;
`
